@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body{
  font-family: 'Poppins', sans-serif;
}

html { 
  background: url('./images/EL.jpg') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.20);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.65);
}

button:focus, input:focus{
  outline: none;
}

.main-container{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.nav-bar{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 10%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 80px;
  padding-right: 0px;
  /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

.nav-bar h1{
  font-size: 48px;
  color: antiquewhite;
}

.nav-button-container{
  margin-right: 160px;
}

.nav-bar button{
  border-radius: 50%;
  width: 64px;
  height: 64px;
  background-color: rgba(255, 255, 255, 0.0);
  margin-right: 20px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  cursor: pointer;
}

.linkedin{
  background-image: url('./images/LI-In-Bug.png')!important;
  background-size: cover!important;
}
.facebook{
  background-image: url('./images/f_logo_RGB-White_114.png')!important;
  background-size: cover!important;
}
.github{
  background-image: url('./images/GitHub-Mark-Light-120px-plus.png')!important;
  background-size: cover!important;
}
.youtube{
  background-image: url('./images/youtube_social_circle_white.png')!important;
  background-size: cover!important;
}

.nav-bar button:hover{
  background-color: rgba(127, 255, 212, 0.9);
}

.sidebar{
 margin-top: 220px;
 margin-left: 80px;
 width: 160px;
}

.sidebar-item{
  color: antiquewhite;
  cursor: pointer;
  width: auto;
}

.sidebar-item:hover{
  text-shadow: 10px 0px 40px rgba(255, 255, 255, 0.98);
}

.center-panel{
  position: absolute;
  top: 25%;
  left: 25%;
  width: 65%;
  height: 50%;
  color: white;
  overflow-y: scroll;
  white-space: pre-line;
}

.center-panel-portfolio{
  position: absolute;
  top: 20%;
  left: 25%;
  width: 65%;
  height: 60%;
  color: white;
  padding: 30px;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.vid-box{
  margin: 16px;
  display: flex;
  flex-direction: row;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.vid-box h1{
  width: 20%;
}
.vid-box p{
  width: 50%;
  padding-left: 32px;
}


.blog-list{
  position: absolute;
  top: 20%;
  left: 25%;
  width: 45%;
  height: 60%;
  color: white;
  padding: 30px;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

.blog-item{
  margin: 30px;
  cursor: pointer;
}

.blog-item:hover{
  color: aquamarine;
  text-shadow: 10px 0px 40px rgba(255, 255, 255, 0.98);
}

.blog-window{
  position: absolute;
  top: 20%;
  left: 25%;
  width: 65%;
  height: 60%;
  color: white;
  padding: 30px;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

.blog-window h1{
  font-size: 48px;
}
.blog-window h2{
  font-size: 36px;
}
.blog-window h3{
  font-size: 30px;
}

.blog-window p{
  font-size: 22px;
}

.resume-window{
  position: absolute;
  top: 20%;
  left: 25%;
  width: 50%;
  height: 60%;
  color: white;
  padding: 30px;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  white-space: pre-line;
}
.resume-window h4{
  margin: 0px;
}
.resume-window h5{
  margin: 0px;
}
.work-title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.footer{
  bottom: 0px;
  display: flex;
  align-items: end;
  height: 30%;
  margin-bottom: 10%;
  margin-left: 40%;
  align-items: flex-end;
  color: rgba(255, 255, 255, 0.38);
}